import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { LargeLarr, LargeRarr } from './Svg'
import Image from '~components/Image'
import { animate, motion, useMotionValue } from 'framer-motion'
import useWindow from '~utils/useWindow'

const MobileImageSlider = ({ className, images, totalImages }) => {
	const window = useWindow()
	const ref = useRef()
	const x = useMotionValue(0)
	const [isDragging, setIsDragging] = useState(false)
	const [sliderWidth, setSliderWidth] = useState(0)
	const [sliderChildrenWidth, setSliderChildrenWidth] = useState(0)
	const [sliderConstraints, setSliderConstraints] = useState(0)
	const [slides, setSlides] = useState()

	const goToNext = () => {
		let posX = 0 - x.current
		const nextSlides = slides.filter(slide => slide > posX)
		const newPos = nextSlides[0] < sliderConstraints ? -nextSlides[0] : -sliderConstraints
		const controls = animate(x, newPos, {
			type: 'spring',
			stiffness: 33,
			// onComplete: v => {}
		})
		return controls.stop
	}

	const goToPrev = () => {
		let posX = 0 - x.current
		const prevSlides = slides.filter(slide => slide < posX)
		const newPos = prevSlides[prevSlides.length - 1] > 0 ? -prevSlides[prevSlides.length - 1] : 0
		const controls = animate(x, newPos, {
			type: 'spring',
			stiffness: 33,
			// onComplete: v => {}
		})
		return controls.stop
	}

	useEffect(() => {
		if (!ref && !ref.current) return
		x.stop()
		x.set(0)

		const calcSliderChildrenWidth = () => {
			setSliderChildrenWidth(
				ref?.current?.scrollWidth
			)
		}
		calcSliderChildrenWidth()

		const calcSliderWidth = () => {
			setSliderWidth(ref?.current?.clientWidth)
		}
		calcSliderWidth()

		const calcSliderConstraints = () => {
			setSliderConstraints(ref?.current?.scrollWidth - sliderWidth)
		}
		calcSliderConstraints()

		const calcSlides = () => {
			const children = ref?.current.children
			if(children){
				const slides = [...children].filter(child => child.nodeName === 'DIV')
				setSlides(slides.map(marker => marker.offsetLeft))
			}
		}
		calcSlides()

	}, [ref, window?.innerWidth])

	return (
		<Wrap className={className}>
			<ImagesWrap>
				<Images
					drag="x"
					ref={ref}
					initial={{ x: 0 }}
					style={{ x }}
					onDragStart={() => setIsDragging(true)}
					onDragEnd={() => setIsDragging(false)}
					dragConstraints={{ left: 0 - sliderConstraints, right: 0}}
				> 
					{images?.map(image => (
						<MobileImage key={image.asset._id} image={image}  aspectRatio={1}/>
					))}
				</Images>
			</ImagesWrap>
			<MobileArrows>
				<ArrowButton
					onClick={()=> goToPrev()}
					css={css`&:hover{svg {transform: translateX(2px);}}`}
				><LargeLarr/></ArrowButton>
				<ArrowButton
					onClick={()=> goToNext()}
					css={css`&:hover{svg {transform: translateX(2px);}}`}
				><LargeRarr/></ArrowButton>
			</MobileArrows>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const ImagesWrap = styled.div`
	overflow: hidden;
	cursor: grabbing;
	margin-bottom: var(--m);
	margin-left: -20px;
	margin-right: -20px;
`
const Images = styled(motion.div)`
	white-space: nowrap;
	> * {
		white-space: normal;
	}
`
const MobileImage = styled(Image)`
	display: inline-block;
	width: 100vw;
`
const MobileArrows = styled.div`
	display: flex;
	justify-content: space-between;	
	grid-column: span 6;
	margin-bottom: var(--m);
`
const ArrowButton = styled.button`
	svg {
		width: 20px;
		transform: translateX();
		transition: transform 0.3s;
	}
`

MobileImageSlider.propTypes = {
	className: PropTypes.string,
	images: PropTypes.array,
	totalImages: PropTypes.number,
}

export default MobileImageSlider