import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Accordion from './Accordion'
import RichText from './RichText'

const AccordionSet = ({ className, items }) => {
	return (
		<Wrap className={className}>
			{items?.map(item => (
				<AccordionItem 
					title={item.title} 
					key={item.title} 
					constrained 
				>
					<RichText content={item.text}/>
				</AccordionItem>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
	
`
const AccordionItem = styled(Accordion)`
	border-top: 1px solid var(--black);
`

AccordionSet.propTypes = {
	className: PropTypes.string,
	items: PropTypes.array,
}

export default AccordionSet