import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import { Link } from 'gatsby'
import { Plus } from './Svg'
import resolveLink from '../utils/resolveLink'
import Button from '~components/Button'
import { useCart } from '~context/siteContext'
import useSiteSettings from '~utils/useSiteSettings'
import RichText from './RichText'

const Overlay = ({ className, variant, qty, onClose, message, link, linkText }) => {
	const { addToCartSuccessMessage } = useSiteSettings()
	const isVoucher = variant.content?.shopify?.variantTitle?.includes('$')

	const { openCart } = useCart()

	const close = ()=> {
		if (onClose) {
			onClose()
		}
	}
	const closeAndOpenCart = ()=> {
		close()
		openCart()
	}

	return (
		<Wrap className={className}>
			{variant ?
				<Product>
					<Content>	
						<div>
							<ProductMessage><RichText content={addToCartSuccessMessage}/></ProductMessage>
							<Link to={resolveLink(variant)} className='h3'>{variant.content.shopify.variantTitle ?? variant.content.shopify.productTitle}</Link>
							<Dimensions>{variant.content.dimensions}</Dimensions>
							<Qty>Qty: {qty}</Qty>
							{!isVoucher && <Price>$<span className='numbers'>{Math.floor(variant.content.shopify.price)}</span></Price>}
						</div>		
						<ViewBag className='h3' onClick={()=> closeAndOpenCart()}>View your bag</ViewBag>
					</Content>
					<ProductImage image={variant.content?.thumb} aspectRatio={1} css={css``}/>
				</Product>
				:
				<Content>
					<h3>{message}</h3>
					<CustomButton link={link}>{linkText}</CustomButton>
				</Content>
			}
			<Close 
				onClick={() => close()}
			><Cross/>
			</Close>
		</Wrap>
	)
}

const Wrap = styled.div`
	background-color: var(--beige);
	border: 1px solid var(--black);
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-column-gap: var(--s);
	padding: var(--m);
	box-sizing: border-box;
	width: 467px;
	min-height: 235px; 
	${mobile}{
		width: auto;
	}
`
const Product = styled.div`
	grid-template-columns: 1fr max-content;
	display: grid;
`
const ProductMessage = styled.h3`
	margin-bottom: var(--s);
`
const Dimensions = styled.h3`
	margin-bottom: 2px;
`
const Qty = styled.h3`
	margin-bottom: var(--xxs);
`
const Price = styled.h3`
	margin-bottom: var(--xxs);
`
const ProductImage = styled(Image)`
	width: 84px;
  .gatsby-image-wrapper{
    height: 100%; 
    object-fit: cover;
  }
`
const Content = styled.div`
	display: grid;
	grid-template-rows: 1fr max-content;
	justify-items: start;
`
const CustomButton = styled(Button)`
	align-self: end;
`
const ViewBag = styled(Button)`
`
const Close = styled.button`
  display: inherit;
`
const Cross = styled(Plus)`
	width: 15px;
	height: 15px;
	transform: rotate(45deg);
	position: relative;
	${mobile}{
		width: 13px;
		height: 13px;
	}
`
Overlay.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.object,
	qty: PropTypes.object,
	onClose: PropTypes.func,
	message: PropTypes.string,
	link: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	linkText: PropTypes.string,
}

export default Overlay